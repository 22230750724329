







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { WRITE_NOTE } from '../../../store/actions';
import { ConservatorshipDetails, WriteNote } from '../../../types';
import { ApiResponse } from '@/components/types';
import WysiwygEditor from '@/components/WysiwygEditor.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    WysiwygEditor
  }
})
export default class WriteNoteDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(WRITE_NOTE) writeNote!: (params: WriteNote) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: WriteNote = {
    conservatee_id: this.conservatorship.id,
    note: this.conservatorship.additionalNote
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatee_id: this.conservatorship.id,
        note: this.conservatorship.additionalNote
      };

      return;
    }

    setTimeout(() => {
      this.error = null;
    }, 200);
  }

  async save() {
    this.error = null;

    return await this.writeNote(this.value);
  }
}
